.launch-button-backdrop{
  transform: translate( calc(-50% - 10px), calc(-50% + 20px));
}
.launch-button:hover{
  transform: translate(-50%, -50%) scale(1.2);
}
.launch-button {
  background: #fefefe;
  /* Transparent background with slight white tint */
  /* border-top: 10px red solid; */
  /* border-left: 8px #dee1e2 solid;
  border-bottom: 5px #21a3be solid; */
  border-radius: 50%;
  cursor: pointer;
  backdrop-filter: blur(0.1px); 
  backdrop-filter: blur(10px); /* Frosted glass effect */
  box-shadow:
    -5px -5px 15px rgba(0, 0, 0, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.1),
    inset 5px 5px 10px rgba(0, 0, 0, 0.1),
    inset -5px -5px 10px rgba(0, 0, 0, 0.1);
  color: #222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 15px; */
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.launch-button .bands {
  display: flex;
  margin: 10px;
}
.launch-button .band-1 {
  display: flex;
  flex-direction: column;
  height: 60%;
  align-items: center;
  justify-content: center;
}

.launch-button .band-2 {
  height: 20%;
  background: linear-gradient(90deg, rgba(3,106,131,1) 0%, rgba(2,126,133,1) 35%, rgba(0,181,138,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.launch-button .band-3 {
  height: 20%;
  color: #99d6d7;
  background: radial-gradient(circle, rgba(157,211,215,1) 25%, rgba(168,213,227,1) 75%);
  transform: translateY(0.5rem) scale(1.4);
}

.curtain {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.curtain .curtain__wrapper {
  width: 100%;
  height: 100%;
  /* input[type=checkbox] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      z-index: 100;
      opacity: 0;
      top: 0;
      left: 0;
    } */
}

.curtain__panel {
  display: flex;
  align-items: center;
  background: #94becf;
  color: #fff;
  float: left;
  position: relative;
  width: 50%;
  height: 100vh;
  transition: all 1s ease-out;
  z-index: 2;
}

.curtain__panel--left {
  justify-content: flex-end;
  transform: translateX(-100%);
  background: #94becf;
}
.curtain__panel--right {
  justify-content: flex-start;
  transform: translateX(100%);
  background:#94becf; 
}

.curtain__wrapper.checked div.curtain__panel--left {
  transform: translateX(0);
  background: radial-gradient(circle at 100% 50%, rgba(11,91,115,1) 0%, rgba(149,189,207,1) 14rem);
}

.curtain__wrapper.checked div.curtain__panel--right {
  transform: translateX(0);
  background: radial-gradient(circle at 0% 50%, rgba(11,91,115,1) 0%, rgba(149,189,207,1) 14rem);
}

.curtain__content {
  height: 100%;
  position: absolute;
  z-index: 1;
  width: 100%;
}

.circle-grow-1 {
  --size: 24px;
  --color: #2687a5bd;
  --animation-timing-function: linear;
  --animation-duration: 1s;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: var(--color);
  opacity: 0;
  transform: scale(0);
  animation: var(--animation-timing-function) var(--animation-duration) infinite
    circle-grow-1-animation;
}

@keyframes circle-grow-1-animation {
  0% {
    opacity: 1;
    transform: scale(10);
  }

  100% {
    opacity: 0;
    transform: scale(0.3);
  }

  /* 100% {
      opacity: 0;
      transform: scale(1);
    } */
}
