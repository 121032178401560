@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* HTML: <div class="loader"></div> */
.loader {
    height: 2em;
    width: 2em;
    left: calc(50% - 1em);
    top: calc(50% - 1em);
    position: absolute;
    border-radius: 50%;
    background-color: #2687a5bd;
    animation: 1.5s pulse infinite ease-in-out;
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #2687a5bd;
    }
  
    100% {
      box-shadow: 0 0 0 2em #69ffa800;
    }
}